<template>
  <div class="TenderAnnouncement">
    <h3>集采中标候选人公示</h3>
    <!-- 根据条件搜索    -->
    <div class="StyleBox">
      <div class="searchItem">
        <el-form
          :model="form"
          label-position="center"
          label-width="80px"
          inline
          ref="form"
        >
          <el-form-item label="标题">
            <el-input
              style="width: 190px"
              v-model="form.tenderTitle"
              placeholder="用户名"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="项目编号">
            <el-input
              style="width: 190px"
              v-model="form.tenderCode"
              placeholder="项目编号"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="招标单位">
            <el-input
              style="width: 190px"
              v-model="form.tenderType"
              placeholder="招标单位"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 项目投标说明 -->
      <div class="ProductIntroduction">
        <span>项目投标说明</span>
        <el-input
          placeholder="请输入内容"
          v-model="form.others"
          maxlength="500"
          type="textarea"
        >
        </el-input>
      </div>
      <!-- 表格 -->
      <div class="tableBox">
        <span>中标候选人详情：</span>
        <table-page :data="tableData" :columns="columns" :border="true">
          <template slot="winnerPrice" slot-scope="scope">
            <el-input
              placeholder="指导价"
              type="number"
              min="1"
              v-model="scope.row.winnerPrice"
            >
            </el-input>
          </template>

          <template slot="winnerName" slot-scope="scope">
            <el-input type="textarea" autosize v-model="scope.row.winnerName">
            </el-input>
          </template>
        </table-page>
      </div>
      <!-- 报名时间 -->
      <div class="RegistrationTime">
        <div class="LProductIntroductionItem">
          <span>公告截止时间</span>
          <el-date-picker
            v-model="form.endTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请输入审批时间"
          >
          </el-date-picker>
        </div>
      </div>
      <!-- 相关附件 -->
      <div class="RelatedAnnex">
        <div class="ProductIntroduction">
          <span class="title">其他说明</span>
          <div class="content">
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              name="files"
              :auto-upload="false"
              :on-change="handleChangeUp"
              :file-list="fileList"
              :show-file-list="false"
            >
              <p>
                <!-- <el-button type="primary">更换</el-button> -->
                <i class="el-icon-upload2"></i>
              </p>
            </el-upload>
          </div>
          <div class="UpDoc">
            <div class="UpDocTitle">
              <li
                v-for="(item, index) in fileList"
                :key="index"
                @click="Download(item)"
              >
                {{ item.FilesName }}
              </li>
            </div>
            <div class="Tips">
              *请确认信息输入无误，一经发布，项目信息无法修改
            </div>
          </div>
        </div>
      </div>
      <!-- 发布按钮 -->
      <div class="PublishBtn">
        <el-button type="primary" @click="pay()">发布</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { download } from "@/api/ChargeUp";
import { uploadFile } from "@/api/fileApi";
import TablePage from "@/components/table/CallForBids-page";
import {
  selectDetailTenders,
  saveSuccessfulBidder,
} from "@/api/CollectivePurchase";
import { MessagePublicity } from "../memberCenter/CollectionMining/tableColumn";

export default {
  name: "NowTenderAnnouncement",

  components: {
    TablePage,
  },
  data() {
    return {
      form: {
        tenderTitle: "",
        tenderType: "",
        tenderCode: "",
        others: "",
        createTime: "",
      },
      keyForm: 1,
      types: {},
      fileList: [],
      files: [],
      tableData: [],
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
      columns: MessagePublicity,
      textarea: "",
      procurementId: "",
    };
  },

  mounted() {
    this.initShow();
  },

  methods: {
    async initShow() {
      let obj = this.$route.query.tenderId;

      this.procurementId = obj.procurementId;
      let params = obj.tenderId;
      console.log(params, "params");
      let res = await selectDetailTenders(params);
      this.tableData = res.data.newAgriculturalVoList;
      this.form.tenderTitle = res.data.tenderTitle;
      this.form.tenderType = res.data.tenderingOrganization;
      this.form.tenderCode = res.data.tenderCode;
      this.form.others = res.data.others;
      // this.fileList = JSON.parse(res.data.files);
    },
    //文件上传
    async handleChangeUp(file, fileList) {
      console.log(fileList, "这是fileList");
      let param = new FormData();
      param.append("catalog", "financeAdd");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      console.log(res, "这是res");
      console.log(typeof this.fileList, "查看fileList");
      // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
      let FilesName = res.data.sourceFileName;
      let imgUrl = res.data.url;
      let item = {
        FilesName: FilesName,
        imgUrl: imgUrl,
      };
      console.log(item, "查看item");
      this.fileList.push(item);
    },
    //发布
    async pay() {
      let procurementId = this.procurementId;
      let newFile = [];
      this.fileList.forEach((item) => {
        newFile.push({
          FilesName: item.FilesName,
          imgUrl: item.imgUrl,
        });
        // console.log(newFile, "点击查看11111");
      });
      let files = JSON.stringify(newFile);
      let params = {
        ...this.form,
        successfulBidderList: this.tableData,
        procurementId,
        files,
        tenderId: this.$route.query.tenderId.tenderId,
      };
      console.log(params);
      let res = await saveSuccessfulBidder(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
        this.$router.back();
      } else {
        this.$message.error(res.msg);
      }
    },
    //下载
    async Download(file) {
      console.log(file);
      let itemList = file;
      let res = await download({ ossFilePath: itemList.imgUrl });
      if (res) {
        let blob = res;

        let ToName = itemList.FilesName;
        const fileName = ToName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        // "|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.TenderAnnouncement {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
  text-align: left;
  padding-bottom: 128px;
  .StyleBox {
    width: 95%;
    margin: 0 auto;
  }
  h3 {
    padding: 10px 10px;
  }

  /*根据条件搜索*/
  .searchItem {
    .el-form {
      display: flex;
      flex-wrap: wrap;
    }
  }
  /*其他说明*/
  .ProductIntroduction {
    width: 80%;
    display: flex;
    padding-bottom: 10px;
    span {
      width: 150px;
      font-weight: bold;
      display: flex;
    }
    .el-textarea {
      height: 150px;
      /deep/textarea {
        height: 150px;
      }
    }
  }
  /*报名时间*/
  .RegistrationTime {
    display: flex;
    .LProductIntroductionItem {
      margin-top: 50px;
      span {
        width: 127px;
        font-weight: bold;
        flex-direction: column;
        justify-content: space-around;
        display: inline-block;
      }
    }
  }
  /*相关附件*/
  .RelatedAnnex {
    .ProductIntroduction {
      width: 80%;
      height: 50px;
      display: flex;
      margin-top: 50px;
      padding-bottom: 10px;
      padding-left: 20px;
      .title {
        width: 150px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .content {
        .el-icon-upload2 {
          font-size: 50px;
          font-weight: bold;
        }
      }
      .UpDoc {
        padding-left: 20px;
        .UpDocTitle {
          padding-top: 10px;
          display: flex;
          list-style: none;
          li {
            padding: 0px 8px;
            text-decoration: underline;
          }
        }
        .Tips {
          padding-top: 10px;
          color: #ec808d;
        }
      }
    }
  }
  /*发布按钮*/
  .PublishBtn {
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    .el-button {
      width: 150px;
    }
  }
}
</style>
